<template>
  <div class="header-notice">
    <el-dropdown @command="handleUpdateClick">
      <div>
        <el-badge :value="needList.length?needList.length:0" class="item">
          <i class="el-icon-close-notification" style="font-size:20px" />
        </el-badge>
      </div>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="update" v-for="(item,index) in needList" :key="index" ><i class="el-icon-warning" style="font-size:10px" />系统升级了，最新版本{{item.version}}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
    export default {
        name: 'User',
        data () {
            return {
                needList: []
            }
        },
        mounted () {
          this.getNotict()
        },
        methods: {
          handleUpdateClick(command) {
            this.$router.push({ path: '/set/system-update'})
          },
          getNotict () {
              this.http.get('system/get_system_version')
                .then((res) => {
                  if (res.status == 200) {
                    this.needList = res.data.updateInfo;
                  } else {
                    this.$message.error(res.message);
                  }
                }).catch((res) => {
                this.$message.error(res.message);
              });
            },
        }
    }
</script>
<style lang="less">
.header-notice{
  margin-right: 30px;
}
.item {
  margin-top: 0px;
  margin-right: 0px;
}
.header-notice .ivu-dropdown-item{
  font-size: 14px!important;
  font-weight: 400;
  line-height: 22px;
  color: #515a6e;
}
.header-notice .ivu-dropdown-item~.ivu-dropdown-item{
  border-top: 1px solid #e8eaec;
}
.header-notice .iconImg{
  width: 32px;
  height: 32px;
  line-height: 32px;
  border-radius: 50%;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  vertical-align: middle;
}
.header-notice .ivu-dropdown{
  height: 30px;
  line-height: 30px;
}
.header-notice .ivu-dropdown .ivu-select-dropdown{
  margin-top: 22px;
}
.header-notice .ivu-badge-dot{
  z-index: 0!important;
}
</style>
