import 'default-passive-events'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VCharts from 'v-charts'
import Element from 'element-ui';
import './theme/index.css';
import './theme/xlicon.css';

import http from './utils/request.js' //找到该文件,修改API网址

import store from './store'
import ElTreeSelect from 'el-tree-select';
import We7CopyRight from './components/We7CopyRight.vue';

// import 'xe-utils'
// import VXETable from 'vxe-table'
// import 'vxe-table/lib/index.css'
//
// Vue.use(VXETable);

import Cookies from 'js-cookie'
import 'normalize.css/normalize.css'
import './styles/element-variables.scss'
import '@/styles/index.scss' // global css

import i18n from './lang' // internationalization
import './icons' // icon
import './permission' // permission control
import './utils/error-log' // error log
import * as filters from './filters'
import settings from "./settings.js"; // global filters

Vue.use(ElTreeSelect);
Vue.component('We7CopyRight', We7CopyRight);

Vue.prototype.http = http;	//全局调用axios

Vue.config.productionTip = false;

Vue.use(VCharts);

//关闭vue调试
const isDebug_mode = process.env.NODE_ENV !== 'production';
Vue.config.debug = isDebug_mode;
Vue.config.devtools = isDebug_mode;
Vue.config.productionTip = isDebug_mode;

Vue.prototype.$store = store;
var myVueEventHub = new Vue();
Vue.prototype.$eventHub = myVueEventHub;

var serverHost = window.location.origin;
if (window.location.href.indexOf('addons') === -1) {
  Vue.prototype.$isWeiQin = false;
  Vue.prototype.$serverRequestRoot = serverHost;
} else {
  Vue.prototype.$isWeiQin = true;
  Vue.prototype.$serverRequestRoot = serverHost + '/addons/xunlian_suyuan/core/index.php';
}

if (process.env.NODE_ENV === 'production') {
  Vue.prototype.$ue = "/front/static/UEditor/";  //百度编辑器路径
  Vue.prototype.$public = "";
  Vue.prototype.$ue_uploads = settings.apiBaseURL + "/index.php/admin/ue_uploads";//百度编辑器上传路径
} else {
  Vue.prototype.$ue = "/public/static/UEditor/";  //百度编辑器路径
  Vue.prototype.$public = "";
  Vue.prototype.$ue_uploads = settings.apiBaseURL + "/index.php/admin/ue_uploads";//百度编辑器上传路径
}

// if (process.env.NODE_ENV !== 'production') {
//   const {mockXHR} = import('../mock')
//   mockXHR()
// }

Vue.use(Element, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

window.vue = new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');

var loadingtip = document.getElementById("loader-wrapper").style.display = "none";
