import request from '@/libs/request.js'
export function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/admin/get_userinfo',
    method: 'post',
  })
}
// export function getInfo() {
//   return http.post({
//     url: '/admin/get_userinfo',
//   })
// }
export function logout() {
  return request({
    url: '/admin/logout',
    method: 'post'
  })
}
export function get() {
  return request({
    url: '/admin/logout',
    method: 'post'
  })
}
