import Vue from 'vue'
import SvgIcon from '@/components/SvgIcon/index.vue'

// register globally
Vue.component('svg-icon', SvgIcon);

const req = import.meta.globEager('./svg/*.svg');
// debugger
// const requireAll = requireContext => Object.keys(requireContext).map(requireContext);
// requireAll(req)
