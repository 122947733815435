
<div class="drawer-container">
  <div>
    <h3 class="drawer-title">{{ $t('settings.title') }}</h3>

    <div class="drawer-item">
      <span>{{ $t('settings.theme') }}</span>
      <theme-picker style="float: right;height: 26px;margin: -3px 8px 0 0;" @change="themeChange" />
    </div>

    <div class="drawer-item">
      <span>{{ $t('settings.tagsView') }}</span>
      <el-switch v-model="tagsView" class="drawer-switch" />
    </div>

    <div class="drawer-item">
      <span>{{ $t('settings.fixedHeader') }}</span>
      <el-switch v-model="fixedHeader" class="drawer-switch" />
    </div>

    <div class="drawer-item">
      <span>{{ $t('settings.sidebarLogo') }}</span>
      <el-switch v-model="sidebarLogo" class="drawer-switch" />
    </div>

  </div>
</div>
