
  <div class="navbar">
    <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container"
               @toggleClick="toggleSideBar"/>

    <breadcrumb id="breadcrumb-container" class="breadcrumb-container"/>
    <div class="right-menu">
      <template>
        <search id="header-search" class="right-menu-item"/>
        <error-log class="errLog-container right-menu-item hover-effect"/>
        <el-tooltip content="全屏" effect="dark" placement="bottom">
          <screenfull id="screenfull" class="right-menu-item hover-effect"/>
        </el-tooltip>
        <system-setting class="right-menu-item hover-effect">
          <settings></settings>
        </system-setting>
        <header-notice class="right-menu-item hover-effect">
        </header-notice>
      </template>
      <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click" @command="head">
        <div class="avatar-wrapper">
<!--          <img src="../../public/images/avatar.png" class="user-avatar">-->
          <div style="display: inline">{{ name }}</div>
          <i class="el-icon-caret-bottom"/>
        </div>

        <el-dropdown-menu slot="dropdown" @command="edit">
          <el-dropdown-item divided command="edit">
            <span style="display:block;">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item divided command="lout">
            <span style="display:block;">{{ $t('navbar.logOut') }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <el-dialog title="" :visible.sync="dialogFormVisible" width="35%" center>
      <el-form :model="form">
        <el-form-item label="原密码" :label-width="formLabelWidth">
          <el-input type="password" v-model="form.old_psw" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth">
          <el-input type="password" v-model="form.new_psw" auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item label="再次输入密码" :label-width="formLabelWidth">
          <el-input type="password" v-model="form.password2" auto-complete="off" @keyup.enter.native="edit"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="edit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
