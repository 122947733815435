
<div class="header-notice">
  <el-dropdown @command="handleUpdateClick">
    <div>
      <el-badge :value="needList.length?needList.length:0" class="item">
        <i class="el-icon-close-notification" style="font-size:20px" />
      </el-badge>
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item command="update" v-for="(item,index) in needList" :key="index" ><i class="el-icon-warning" style="font-size:10px" />系统升级了，最新版本{{item.version}}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</div>
