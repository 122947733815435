
<div class="sidebar-logo-container" :class="{'collapse':collapse}">
  <transition name="sidebarLogoFade">
    <router-link v-if="collapse" key="collapse" class="sidebar-logo-link" to="/">
      <img v-if="siteInfo&&siteInfo.login_logo" :src="siteInfo.login_logo" class="sidebar-logo">
    </router-link>
    <router-link v-else key="expand" class="sidebar-logo-link" to="/">
      <img v-if="siteInfo&&siteInfo.login_logo" :src="siteInfo.login_logo" class="sidebar-logo">
    </router-link>
  </transition>
</div>
