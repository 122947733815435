import axios from 'axios'
import {MessageBox, Message} from 'element-ui'
import store from '@/store'
import {getToken} from '@/utils/auth'
import {Api_url} from '@/common/config'
import setting from "@/settings";

// create an axios instance
const service = axios.create({
  baseURL: Api_url, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 15000 // request timeout
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    let token = getToken();
    if (token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['token'] = getToken()
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(

  response => {
    const res = response
    if (res.status === 200) {
      return res.data
    } else {
      if (res.status === 401) {
        MessageBox.confirm('您已经退出，请重新登录', '确认退出', {
          confirmButtonText: '重新登录',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          localStorage.clear(); //结果为401，表示无权限，或token过期；返回login页面
          store.dispatch('user/resetToken').then(() => {
            location.reload()
          })
        })
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    }
  },
  error => {
    //超时忽略
    var igoreUrl = false;
    for (var i = 0; i < setting.timeoutWhiteUrl.length; i++) {
      if (error.message.indexOf('timeout') !== -1) {
        if (url.indexOf(setting.timeoutWhiteUrl[i])) {
          igoreUrl = true;
          break;
        }
      }
    }
    if (!igoreUrl) {
      return Promise.reject(error)
    }
  }
)
function request(type,url,param){
  return new Promise((resolve, reject) => {
    service({
      method: type,
      url,
      data: param
    }).then(res => {
      resolve(res)
    }).catch(err => {
      reject(err.response)
    })
  })
}

export default {
//get请求
  get(url, param) {
    return new Promise((resolve, reject) => {
      service({
        method: 'get',
        url,
        params: param
      }).then(res => {
        if (res.status === 200) {
          resolve(res);
        } else {
          reject(res);
        }
      }).catch(err => {
        reject(err.data)
      })
    })
  },

  put(url, param) {
    return new Promise((resolve, reject) => {
      service.put(url, param)
      .then(res => {
          if (res.status === 200) {
            resolve(res);
          } else {
            reject(res);
          }
        }).catch(err => {
          reject(err.data)
        })
    })
  },

  post(url, param,callback=null) {
    return new Promise((resolve, reject) => {
      service({
        method: 'post',
        url,
        data: param,
      }).then(res => {
        if (res.status === 200) {
          resolve(res);
        } else {
          reject(res);
        }
      }).catch(err => {
        reject(err.data)
      })
    })
  },
  delete(url, param) {
    const that = this
    return new Promise((resolve, reject) => {
      service({
        method: 'delete',
        url,
        data: param,
      }).then(res => {
        if (res.status === 200) {
          resolve(res);
        } else {
          reject(res);
        }
      }).catch(err => {
        reject(err.data)
      })
    })
  },
  service
}
