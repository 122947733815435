
<el-breadcrumb class="app-breadcrumb" separator="/">
  <transition-group name="breadcrumb">
    <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
      <span v-if="item.redirect==='noRedirect'||index==levelList.length-1" class="no-redirect">
        {{ generateTitle(item.meta.title) }}
      </span>
      <a v-else @click.prevent="handleLink(item)">{{ generateTitle(item.meta.title) }}</a>
    </el-breadcrumb-item>
  </transition-group>
</el-breadcrumb>
