import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex);

// https://webpack.js.org/guides/dependency-management/#requirecontext
const modulesFiles = import.meta.globEager('./modules/*.js')
// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules =Object.keys(modulesFiles).reduce((modules, modulePath) => {
  // set './app.js' => 'app'\
  const moduleName = modulePath.replace(/^\.\/modules\/(.*)\.\w+$/, '$1');
  const value = modulesFiles[modulePath];
  modules[moduleName] = value.default;
  return modules
}, {})
const store = new Vuex.Store({
  modules,
  getters
})

export default store
