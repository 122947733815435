<template>
    <div>
      <i :class="itemClass" style="" v-if="elIcon"></i>
      <span slot='title' v-if="title">{{title}}</span>
      <svg-icon icon-class={icon} v-if="icon"></svg-icon>
    </div>
</template>
<script>
export default {
  name: 'MenuItem',
  props: {
    icon: {
      type: String,
      default: ''
    },
    elIcon: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed:{
    itemClass(){
      return this.elIcon
    }
  },
  mounted() {

  }
}
</script>
