<template>
	<div class="copyright">
		<div style="width: 100%;background-color: #ffffff;margin: 10px 0px;line-height:40px">

		</div>
	</div>
</template>

<script>
	import {getSystemConfig} from '@/libs/tools.js'
	export default {
		name: 'We7CopyRight',
		data() {
			return {
				author:''
			}
		},
		mounted(){
			var that = this;

			this.$nextTick(function(){
				that.author = getSystemConfig('author_code')
			})
		},
		methods: {
		}
	}

</script>

<style scoped  lang="less">
  .copyright{
    text-align: center;
  }

</style>
