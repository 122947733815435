
<div :class="classObj" class="app-wrapper">
  <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside" />
  <sidebar class="sidebar-container" />
  <div :class="{hasTagsView:needTagsView}" class="main-container">
    <div :class="{'fixed-header':fixedHeader}">
      <navbar />
      <tags-view v-if="needTagsView" />
    </div>
    <app-main />
    <We7CopyRight></We7CopyRight>
    <right-panel v-if="showSettings">
      <settings />
    </right-panel>
  </div>
</div>
